<template>
  <div
    :class="{
      'py-sm bg-white md:bg-vdv-grey w-full flex items-center flex-col cursor-pointer':
        props.showLabel,
      'cursor-not-allowed': !props.isAvailable,
    }"
    @click="onToggleState"
  >
    <div
      class="md:w-[50px] w-[40px] h-[40px] md:h-[50px] md:rounded-[25px] rounded-[20px] m-[2px] flex place-items-center justify-center"
      :class="{
        'ease-in duration-300 bg-vdv-base text-white':
          props.isActive || !isClickable,
        'ease-in cursor-not-allowed duration-300 bg-vdv-filter-grey text-vdv-font':
          !props.isAvailable,
        'ease-in duration-300 bg-activesection-alpha text-vdv-font':
          !props.isActive && isClickable && props.isAvailable,
      }"
    >
      <FaIcon classes="fa-lg" :icon-class="`fal ${getIconName() ?? ''}`" />
    </div>
    <div v-if="props.showLabel" class="text-sm text-center pt-2xs">
      {{ getEventTypeName() }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ECourseType } from '@/@types/course-type';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

const props = defineProps({
  courseType: {
    type: String as PropType<ECourseType>,
    required: true,
    default: ECourseType.NONE,
  },
  isActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  isAvailable: {
    type: Boolean,
    required: false,
    default: true,
  },
  isClickable: {
    type: Boolean,
    required: false,
    default: true,
  },
  showLabel: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'toggle', value: boolean): void;
}>();

function onToggleState(): void {
  if (!props.isAvailable || !props.isClickable) {
    return;
  }
  emit('toggle', !props.isActive);
}

function getEventTypeName(): string {
  switch (props.courseType) {
    case ECourseType.HYBRID:
      return t('event.hybrid');
    case ECourseType.INHOUSE:
      return t('event.inhouse');
    case ECourseType.ONLINE:
      return t('event.online');
  }
}

function getIconName() {
  switch (props.courseType) {
    case ECourseType.HYBRID:
      return 'fa-house-signal';
    case ECourseType.INHOUSE:
      return 'fa-house';
    case ECourseType.ONLINE:
      return 'fa-wifi';
    default:
      return '';
  }
}
</script>
